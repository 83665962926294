<template>
  <v-container class="grey lighten-5">
    <v-snackbar
      :timeout="-1"
      :value="showNoti"
      absolute
      right
      color="success"
      outlined
      top
    >
      คำสั่งซื้อล่าสุด {{ tempBuyLastUpdate[0].fullnameLast }}
    </v-snackbar>
    <v-row no-gutters style="height: 100%; background-color: white">
      <v-col cols="12">
        <!-- <v-card class="pa-2 mx-auto" outlined tile>
          {{ dateShow }}
        </v-card> -->
        <v-select
          v-model="selectItem"
          :items="items"
          item-text="text"
          item-value="value"
          @change="newCampaign"
          dense
          outlined
        ></v-select>
      </v-col>

      <v-col :cols="size">
        <v-card class="pa-2 mx-auto" outlined tile> ยอดซื้อ </v-card>
        <div style="background-color: white; padding: 50px">
          <v-text-field outlined readonly v-model="paidOrder">
            <v-icon slot="prepend" color="grey"> mdi-border-all </v-icon>
          </v-text-field>
          <v-text-field outlined readonly v-model="qtyPaid">
            <v-icon slot="prepend" color="grey"> mdi-unity </v-icon>
          </v-text-field>
          <v-text-field outlined readonly v-model="totalPrice">
            <v-icon slot="prepend" color="grey"> mdi-cash-multiple </v-icon>
          </v-text-field>
        </div>
      </v-col>
      <v-col :cols="size">
        <!-- <v-card class="pa-2 mx-auto" outlined tile>
          จำนวน orders ทั้งหมด
        </v-card>
        <div style="background-color: white; padding: 50px">
          <v-text-field outlined readonly v-model="totalOrder">
            <v-icon slot="prepend" color="grey">
              mdi-music-accidental-sharp
            </v-icon>
          </v-text-field>
        </div> -->
      </v-col>

      <v-col :cols="size" class="mx-auto">
        <v-card class="pa-2" outlined tile>
          Map (mouse holder for detail)</v-card
        >
        <div style="background-color: white" v-if="showMap">
          <MarkerMap
            v-if="selectCoupon"
            :coupon="date"
            :updateThailand="updateThailand"
            :watchMap="watchMap"
            style="height: 300px"
          />
          <v-list>
            <v-list-group
              v-for="(item, i) in mapThaiIndex"
              :key="i"
              :value="false"
              prepend-icon="mdi-map-marker"
              @click="zoomThailand(item,$event)"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-title>{{
                  `${item} ( ${sumOrderInPV[item]} orders )`
                }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="(thai, i) in thailand"
                v-if="mapThaiIndex[mapThai[thai.province]] === item"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-map-marker </v-icon>
                </v-list-item-icon>

                <!-- <v-list-item-content> -->
                <div>
                  <v-list-item-title>{{ thai.province }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ thai.num }} orders</v-list-item-subtitle
                  >
                </div>

                <!-- </v-list-item-content> -->
              </v-list-item>
            </v-list-group>

            <!-- <v-list-item v-for="(thai, i) in thailand" :key="i">
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-map-marker </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ thai.province }}</v-list-item-title>
                <v-list-item-subtitle
                  >{{ thai.num }} orders</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </div>
      </v-col>
      <v-col :cols="size" style="height: 400px">
        <v-card class="pa-2" outlined tile> คำสั่งซื้อล่าสุด </v-card>
        <br />
        <div
          v-for="(temp, index) in tempBuyLastUpdate"
          :key="index"
          style="background-color: white; padding: 0px 50px 0px 50px"
        >
          <v-text-field outlined readonly v-model="temp.fullnameLast">
            <v-icon slot="prepend" color="grey"> mdi-account </v-icon>
          </v-text-field>
          <!-- <v-text-field outlined readonly v-model="priceLast">
            <v-icon slot="prepend" color="grey"> mdi-currency-usd </v-icon>
          </v-text-field>
          <v-text-field outlined readonly v-model="dateLast">
            <v-icon slot="prepend" color="grey"> mdi-calendar-range </v-icon>
          </v-text-field> -->
        </div>
      </v-col>
    </v-row>

    <!-- <HelloWorld /> -->
  </v-container>
</template>

<script>
import MarkerMap from "@/components/maps/MarkerMap.vue";
import * as CouSv from "@/services/coupon";
import * as OrderSv from "@/services/order";
import regionUtil from "@/utils/region";
import moment from "moment";
export default {
  components: { MarkerMap },
  data: () => ({
    selectCoupon: false,
    selectItem: null,
    items: [],
    thailand: [],
    totalOrder: 0,
    paidOrder: 0,
    qtyPaid: 0,
    unpaidOrder: 0,
    totalPrice: 0,
    totalUnpaidPrice: 0,
    showMap: false,
    tempBuyLastUpdate: [
      {
        fullnameLast: "",
        // priceLast: 0,
        // dateLast: "",
      },
    ],
    date: {
      dateStart: "",
      dateEnd: "",
      code: "",
    },
    dateShow: "",
    mapThaiIndex: [],
    mapThai: {},
    sumOrderInPV: {
      กรุงเทพมหานครและปริมณฑล: 0,
      ภาคกลาง: 0,
      ภาคเหนือ: 0,
      ภาคตะวันออกเฉียงเหนือ: 0,
      ภาคตะวันออก: 0,
      ภาคตะวันตก: 0,
      ภาคใต้: 0,
    },
    showNoti: false,
    oldValue: {
      dataPaid: [],
      dataUnpaid: [],
      paid: 0,
      unpaid: 0,
    },
    watchMap : "",
    years: {
      2020: [
        {
          month: 12,
          dateStart: "2020-12-01 00:00:00",
          dateEnd: "2020-12-31 23:59:59",
        },
        {
          month: 11,
          dateStart: "2020-11-01 00:00:00",
          dateEnd: "2020-11-30 23:59:59",
        },
        {
          month: 10,
          dateStart: "2020-10-01 00:00:00",
          dateEnd: "2020-10-31 23:59:59",
        },
        {
          month: 9,
          dateStart: "2020-09-01 00:00:00",
          dateEnd: "2020-09-30 23:59:59",
        },
        {
          month: 8,
          dateStart: "2020-08-01 00:00:00",
          dateEnd: "2020-08-31 23:59:59",
        },
      ],
      2021 : [ {
          month: 1,
          dateStart: "2021-01-01 00:00:00",
          dateEnd: "2021-01-31 23:59:59",
        }]
    },
  }),
  async created() {
    // console.log(regionUtil);
    // let selectCampaign = this.$store.getters.selectCampaign;
    // console.log("select", selectCampaign);
    let rsCoupon = await CouSv.getAllCoupon();
    // let cutCoupon = rsCoupon.slice(0, 7);

    // console.log(this.items);
    this.mapThaiIndex = regionUtil.regionThailand();
    this.mapThai = regionUtil.countryInRegionThailand();
    let startOfMonth = moment()
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD HH:mm:ss");
    let dateNow = moment().format("M");
    let yearNow = moment().format("YYYY");
    let month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.dateShow = `orders ${month[dateNow - 1]} ${yearNow}`;
    let endOfMonth = moment()
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD HH:mm:ss");
    this.date.dateStart = moment(startOfMonth).unix();
    this.date.dateEnd = moment(endOfMonth).unix();
    this.date.code = `${this.date.dateStart}-${this.date.dateEnd}`;
    this.items.push({
      text: `Orders ${month[dateNow - 1]} ${yearNow}`,
      value: {
        dateStart: moment(startOfMonth).unix(),
        dateEnd: moment(endOfMonth).unix(),
        code: `${this.date.dateStart}-${this.date.dateEnd}`,
      },
    });
     this.years["2021"].forEach((e) => {
      this.items.push({
        text: `Orders ${month[e.month - 1]} 2021`,
        value: {
          dateStart: moment(e.dateStart).unix(),
          dateEnd: moment(e.dateEnd).unix(),
          code: `${moment(e.dateStart).unix()}-${moment(e.dateEnd).unix()}`,
        },
      });
    });
    this.years["2020"].forEach((e) => {
      this.items.push({
        text: `Orders ${month[e.month - 1]} 2020`,
        value: {
          dateStart: moment(e.dateStart).unix(),
          dateEnd: moment(e.dateEnd).unix(),
          code: `${moment(e.dateStart).unix()}-${moment(e.dateEnd).unix()}`,
        },
      });
    });
    
    //  console.log("check")
    // console.log(this.items)
    // if(){

    // }
    this.selectItem = {
      dateStart: moment(startOfMonth).unix(),
      dateEnd: moment(endOfMonth).unix(),
      code: `${this.date.dateStart}-${this.date.dateEnd}`,
    };
    rsCoupon.forEach((e) => {
      this.items.push({
        text: "Code : " + e.code,
        value: e,
      });
    });
    // let check = 0
    // this.items.forEach((e) => {
    //   if (e.text === selectCampaign.text) {
    //     this.selectItem = e.value;
    //     this.date = e.value;
    //   }
    // });
    this.selectCoupon = true;
    // console.log(5566)
    // this.generateDynamicDashBoard();
    this.newCampaign();
    // console.log(this.selectCoupon)
  },
  computed: {
    size() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 6;
        case "xl":
          return 6;
      }
    },
  },
  methods: {
    newCampaign() {
      this.showMap = false;
      this.date = this.selectItem;
      // this.$store.commit("updateSelectCampaign", {
      //   text: "Code : " + this.selectItem.code,
      //   value: this.selectItem,
      // });
      // console.log(this.date);

      this.generateDynamicDashBoard();
      // this.updateThailand
      // console.log(this.selectItem);
    },
    updateThailand(data) {
      // console.log(data);
      //  console.log(data.length)
      //  console.log(this.thailand.length)
      //   if (data.length !== this.thailand.length) {
      //     console.log("start noti")
      //  this.showNoti = true
      this.sumOrderInPV = {
        กรุงเทพมหานครและปริมณฑล: 0,
        ภาคกลาง: 0,
        ภาคเหนือ: 0,
        ภาคตะวันออกเฉียงเหนือ: 0,
        ภาคตะวันออก: 0,
        ภาคตะวันตก: 0,
        ภาคใต้: 0,
      };
      data.sort(function (a, b) {
        return -a.num + b.num;
      });
      data.forEach((element) => {
        this.sumOrderInPV[this.mapThaiIndex[this.mapThai[element.province]]] +=
          element.num;
      });
      // console.log(this.sumOrderInPV)
      this.thailand = data;
      // }
      //  let _this = this;
      //   setTimeout(function () {
      //     _this.showNoti = false;
      //   }, 3000);
    },
    async generateDynamicDashBoard() {
      try {
        if (this.date.dateStart) {
          var rs = await OrderSv.getOrderDynamicDashboard({
            dateStart: this.date.dateStart,
            dateEnd: this.date.dateEnd,
          });
        } else {
          var rs = await OrderSv.getOrderDynamicDashboard({
            code: this.date.code,
          });
        }
        // console.log(rs);
        // var rs = await OrderSv.getOrderDynamicDashboard(this.date);
        // console.log(rs);
        // if(!this.oldValue.length){

        // }
        if (this.oldValue.dataPaid.length !== rs.dataPaid.length) {
          this.showNoti = true;
          this.oldValue = rs;
          this.tempBuyLastUpdate = [];
          if (rs.dataPaid.length === 0) {
            this.tempBuyLastUpdate.push({
              fullnameLast: "ยังไม่มีคำสั่งซื้อล่าสุด",
            });
          }
          rs.dataPaid.forEach((element, index) => {
            if (index < 3) {
              this.tempBuyLastUpdate.push({
                fullnameLast: `${element.fullName} ${this.numberWithCommas(
                  element.price,
                  2
                )} บาท ${moment
                  .unix(element.updatedAt)
                  .format("DD/MM/YYYY HH:mm:ss")}`,
              });
            }
          });
          this.totalOrder = rs.dataPaid.length + rs.dataUnpaid.length;
          this.paidOrder =
            this.numberWithCommas(rs.dataPaid.length) + " ออเดอร์";
          this.totalPrice = this.numberWithCommas(rs.paid, 2) + " บาท";
          this.qtyPaid = this.numberWithCommas(rs.qtyPaid) + " ลัง";
          this.totalUnpaidPrice = rs.unpaid;
        }
        this.showMap = true;
        let _this = this;
        setTimeout(function () {
          _this.showNoti = false;
        }, 3000);

        // setTimeout(function () {
        //   _this.generateDynamicDashBoard();
        // }, 10000);
      } catch (err) {
        // console.log(err)
        // this.generateDynamicDashBoard();
      }

      // console.log(this.dateLast)
    },
    numberWithCommas(x, decimal = 0) {
      var n = x.toFixed(decimal);
      let comma = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return comma;
    },
    zoomThailand(data){
      this.watchMap = data
    }
  },
};
</script>
