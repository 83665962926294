import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import x5GMaps from 'x5-gmaps'
import store from "@/store";
// Option 1: Just your key
Vue.use(x5GMaps, 'AIzaSyDsncWbqk578X2KuXF6jXttgK_CoGHwOIQ')
// Option 2: With libraries
// Vue.use(x5GMaps, { key: 'AIzaSyDzTk1C8qO5r5VkNUHvJ3m5yDRhxtpVP-4', libraries: ['places'] })
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
