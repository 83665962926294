const sameConfig = {
  BASIC_API_BASE_URL:
    "https://asia-northeast1-nextbest-api.cloudfunctions.net/api",
  AUTH_NAME: "ichitan",
};

const configs = {
  production: {
    ...sameConfig,
  },
  development: {
    ...sameConfig,
  },
};

const NODE_ENV =
  process.env.NODE_ENV === "production"
    ? "production"
    : "development";

// const NODE_ENV  = process.env.NODE_ENV || 'production'
export default configs[NODE_ENV];
