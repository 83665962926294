import moment from "moment";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import routes from "./paths";
import configs from "@/configs";
import store from "@/store";

// const NODE_ENV = process.env.NODE_ENV || 'production'
// const { FRONTEND_BASE_URL, LIFF_ID } = configs

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters.token) {
    await store.dispatch("newToken");
    location.reload();
  } else {
    const currentDate = moment().unix();
    const { expired_date, token } = store.getters.token;
    if (currentDate > expired_date) {
      await store.dispatch("newToken");
      location.reload();
    }
  }
  console.log(store.getters.token)
  next();
});

export default router;
