export class Region {
    regionThailand () {
        return ["กรุงเทพมหานครและปริมณฑล","ภาคกลาง","ภาคเหนือ","ภาคตะวันออกเฉียงเหนือ","ภาคตะวันออก","ภาคตะวันตก","ภาคใต้"]
    }
    countryInRegionThailand() {
        return {
            "กรุงเทพมหานคร" : 0,
            "นครปฐม" : 0,
            "นนทบุรี" : 0,
            "ปทุมธานี" : 0,
            "สมุทรปราการ" : 0,
            "สมุทรสาคร" : 0,
            "กำแพงเพชร" : 1,
            "ชัยนาท" : 1,
            "นครนายก" : 1,
            "นครสวรรค์" : 1,
            "พระนครศรีอยุธยา" : 1,
            "พิจิตร" : 1,
            "พิษณุโลก" : 1,
            "เพชรบูรณ์" : 1,
            "ลพบุรี" : 1,
            "สมุทรสงคราม" : 1,
            "สระบุรี" : 1,
            "สิงห์บุรี" : 1,
            "สุโขทัย" : 1,
            "สุพรรณบุรี" : 1,
            "อ่างทอง" : 1,
            "อุทัยธานี" :1,
            "เชียงราย" : 2,
            "เชียงใหม่" : 2,
            "น่าน" : 2,
            "พะเยา" : 2,
            "แพร่" : 2,
            "แม่ฮ่องสอน" : 2,
            "ลำปาง" : 2,
            "ลำพูน" : 2,
            "อุตรดิตถ์" : 2,
            "กาฬสินธุ์" : 3,
            "ขอนแก่น" : 3,
            "ชัยภูมิ" : 3,
            "นครพนม" : 3,
            "นครราชสีมา" : 3,
            "บึงกาฬ" : 3,
            "บุรีรัมย์" : 3,
            "มหาสารคาม" : 3,
            "มุกดาหาร" : 3,
            "ยโสธร" : 3,
            "ร้อยเอ็ด" : 3,
            "เลย" : 3,
            "ศรีสะเกษ" : 3,
            "สกลนคร" : 3,
            "สุรินทร์" : 3,
            "หนองคาย" : 3,
            "หนองบัวลำภู" : 3,
            "อุดรธานี" : 3,
            "อุบลราชธานี" : 3,
            "อำนาจเจริญ" : 3,
            "จันทบุรี" : 4,
            "ฉะเชิงเทรา" : 4,
            "ชลบุรี" : 4,
            "ตราด" : 4,
            "ปราจีนบุรี" : 4,
            "ระยอง" : 4,
            "สระแก้ว" : 4,
            "กาญจนบุรี" : 5,
            "ตาก" : 5,
            "ประจวบคีรีขันธ์" : 5,
            "เพชรบุรี" : 5,
            "ราชบุรี" : 5,
            "กระบี่" : 6,
            "ชุมพร" : 6,
            "ตรัง" : 6,
            "นครศรีธรรมราช" : 6,
            "นราธิวาส" : 6,
            "ปัตตานี" : 6,
            "พังงา" : 6,
            "พัทลุง" : 6,
            "ภูเก็ต" : 6,
            "ยะลา" : 6,
            "ระนอง" : 6,
            "สตูล" : 6,
            "สงขลา" : 6,
            "สุราษฎร์ธานี" : 6
          }
    }
  }
  
  export const regionUtil = new Region();
  export default regionUtil;