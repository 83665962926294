import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

import configs from '@/configs'

const baseURL = configs.BASIC_API_BASE_URL

const ins = axios.create({ baseURL })

if (store.getters.token) {
  const { token } = store.getters.token
  ins.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const noAuth = axios.create({ baseURL })

export default ins

// Vue.prototype.$http = axios
