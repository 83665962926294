import axios from './axios'


export const getMapByCode = (
  params : any
) => {
  return axios
    .post(`/client/orders/getOrderForCreatedMap`, 
      params
    )
    .then(res => {
      return res.data
    })
}

export const getOrderDynamicDashboard = (
  params : any
) => {
  console.log(params)
  return axios
    .post(`/client/orders/getOrderDynamicDashboard`, 
      params
    )
    .then(res => {
      return res.data
    })
}

