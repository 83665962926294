type State = {
  [key: string]: any;
};

const state: State = {};

const getters = {
  maps: (state: State) => {
    return state;
  },
};

const mutations = {
  updateMaps: (state: State, payload: any) => {
    console.log(payload);
    state[`${payload.code}`] = payload.items;
  },
};

const actions = {};

export default {
  state,
  mutations,
  getters,
  actions,
};
