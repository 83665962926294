import { RouteConfig } from "vue-router";

import Dynamic from "@/views/dynamic.vue";
const paths: RouteConfig[] = [
  {
    path: "/dynamic",
    name: "dynamic",
    component: Dynamic,
  },
  {
    path: '*',
    redirect: '/dynamic'
  }
];

export default paths;
