type State = {
  value?: any;
  text?: string;
};

const state: State = {};

const getters = {
  selectCampaign: (state: State) => {
    return state;
  },
};

const mutations = {
  updateSelectCampaign: (state: State, payload: any) => {
    state["text"] = payload.text;
    state["value"] = payload.value;
  },
};

const actions = {};

export default {
  state,
  mutations,
  getters,
  actions,
};
