
import * as AuthService from '@/services/auth'

type State = {
  token?: any
}

const state: State = {}

const getters = {
  token: (state: State) => {
    return state.token
  }
}

const mutations = {
  setToken: (state: State, payload: any) => {
    state.token = payload
  }
}

const actions = {
  async newToken({ commit, state }: any) {
    const token = await AuthService.getToken()
    commit('setToken', token)
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
