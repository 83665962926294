import { noAuth } from './axios'
import configs from '@/configs'

const { AUTH_NAME } = configs

export const getToken = () => {
  return noAuth.get(`/auth/getToken/${AUTH_NAME}`).then(res => {
    return res.data
  })
}
