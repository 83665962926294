<template>
  <gmaps-map :options="mapOptions">
    <div v-for="(item, i) in items" :key="i">
      <gmaps-marker :options="item.options"> </gmaps-marker>
    </div>
  </gmaps-map>
</template>

<script>
// import Vue from 'vue'
import { gmapsMap, gmapsMarker, gmaps } from "x5-gmaps";
import marker from "@/assets/icons/marker2.png";
import * as OrderSv from "@/services/order";
// const _this = this;
export default {
  name: "MarkerMap",
  props: {
    coupon: Object,
    watchMap: String,
    updateThailand: Function,
  },
  components: { gmapsMap, gmapsMarker },
  data: () => ({
    // coupon: null,
    mapOp: {
      กรุงเทพมหานครและปริมณฑล: {
        center: { lat: 13.955111, lng: 100.938408 },
        zoom: 8,
      },
      ภาคกลาง: {
        center: { lat: 13.955111, lng: 100.938408 },
        zoom: 7,
      },
      ภาคเหนือ: {
        center: { lat: 18.777, lng: 98.984 },
        zoom: 7,
      },
      ภาคตะวันออกเฉียงเหนือ: {
        center: { lat: 16.377, lng: 102.984 },
        zoom: 6.5,
      },
      ภาคตะวันออก: {
        center: { lat: 13.377, lng: 101.984 },
        zoom: 8,
      },
       ภาคตะวันตก: {
        center: { lat: 13.377, lng: 98.984 },
        zoom: 8,
      },
      ภาคใต้: {
        center: { lat: 10.377, lng: 98.984 },
        zoom: 6,
      },
    },
    mapOptions: {
      center: { lat: 13.955111, lng: 100.938408 },
      zoom: 4.8,
      fullscreenControl: false,
      mapTypeControl: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: false,
    },
    items: [
      // {
      //   options: {
      //     position: { lat: 18.8440597, lng: 99.05122179999999 },
      //     icon: marker,
      //     animation: 1,
      //     title: "Marker B",
      //     text: "testtest",
      //     opacity: 0.7,
      //   },
      // },
    ],
    check: 18.8440597,
  }),
  async created() {
    console.log(this.watchMap);
    if (this.mapOp[this.watchMap]) {
      this.mapOptions.center = this.mapOp[this.watchMap].center;
      this.mapOptions.zoom = this.mapOp[this.watchMap].zoom;
    }

    // console.log(this.coupon);
    await this.loadmapAuto();
  },

  methods: {
    async loadmapAuto() {
      // let selectCampaign = this.$store.getters.selectCampaign;
      // this.coupon = selectCampaign.value;
      try {
        if (this.coupon.dateStart) {
          var rs = await OrderSv.getMapByCode({
            dateStart: this.coupon.dateStart,
            dateEnd: this.coupon.dateEnd,
          });
        } else {
          var rs = await OrderSv.getMapByCode({ code: this.coupon.code });
        }

        this.updateThailandX(rs.thailand);
        //  console.log(rs)
        let maps = this.$store.getters.maps;
        // console.log(maps);
        // if (maps[this.coupon.code]) {
        //   if (maps[this.coupon.code].length !== rs.data.length) {
        //     this.items = maps[this.coupon.code];
        //     let start = maps[this.coupon.code].length;
        //     let end = rs.data.length;
        //     let newData = rs.data.slice(start, end);
        //     console.log(newData);
        //     await this.dynamicMap(newData, selectCampaign.value);
        //   } else {
        //     this.items = maps[this.coupon.code];
        //   }
        // } else {
          await this.dynamicMap(rs.data);
        // }
        let _this = this;

        // setTimeout(function () {
        //   // console.log(this.coupon)

        //   //  console.log(_this.coupon)
        //   //   console.log(selectCampaign)
        //   _this.loadmapAuto();
        // }, 10000);
      } catch (err) {
        //  console.log(12345)
        // this.loadmapAuto();
      }
    },
    async dynamicMap(data, value = null) {
      console.log("map", data);
      // let selectCampaign = this.$store.getters.selectCampaign;
      //  this.coupon = selectCampaign.value
      this.items = [];
      // console.log(value)
      // if (value.code === this.coupon.code) {
        for (let i in data) {
          let item = data[i];
          this.items.push({
            options: {
              position: {
                lat: item.lat,
                lng: item.lng,
              },
              icon: marker,
              animation: 3,
              title: item.address,
            },
          });
          // console.log(latlng);
        }
        this.$store.commit("updateMaps", {
          code: this.coupon.code,
          items: this.items,
        });
      // }
    },
    updateThailandX(data) {
      let thailand = [];
      for (let i in data) {
        let item = data[i];
        thailand.push({
          province: i,
          num: item,
        });
      }
      this.updateThailand(thailand);
    },
  },
  watch: {
    watchMap: function (newM, OldM) {
      if (this.mapOp[newM]) {
        //   console.log(this.mapOp[newM])
        //  this.mapOptions = Object.assign(this.mapOp[newM], this.mapOptions);
        this.mapOptions.center = this.mapOp[newM].center;
        this.mapOptions.zoom = this.mapOp[newM].zoom;
      }
      console.log(this.mapOptions);
      // if(newM === " "){

      // }
      // console.log(newM)
      // console.log(OldM)
      // this.answer = 'Waiting for you to stop typing...'
      // this.debouncedGetAnswer()
    },
  },
};
</script>
